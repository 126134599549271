// Module
var code = `<div class="transactions__pages">
	<div class="transactions__empty b-empty-message" rv-if="meta.empty">{ 'transactions.empty' | string }</div>

	<ul class="transactions__page" rv-each-page="pages">
		<li class="transactions__separator">{ page.separator }</li>
		<li class="transactions__item" rv-each-transaction="page.transactions">
			<button class="transactions__button" rv-on-click="transaction.open" rv-class-transactions__button--memo="transaction.model:memo" rv-class-transactions__button--pending="transaction.pending" rv-class-transactions__button--approved="transaction.model:approved" rv-class-transactions__button--cleared="transaction.cleared">

				<div class="transactions__date">
					<span class="transactions__date-big">{ transaction.date.big }</span>
					<span class="transactions__date-small">{ transaction.date.small }</span>
					<span class="transactions__date-full">{ transaction.date.full }</span>
				</div>
				<span class="transactions__amount" rv-class-transactions__amount--negative="transaction.negative">
					{ transaction.model:amount | currency }
				</span>
				<span class="transactions__payee" rv-class-transactions__payee--set="transaction.model:payeeName">
					{ transaction.model:payeeName | fallback transaction.model:pluginsOwnPayeeId }
				</span>
				<span class="transactions__budget" rv-class-transactions__budget--empty="transaction.noBudget">{ transaction.budget }</span>
				<span class="transactions__memo">{ transaction.model:memo }</span>
			</button>
		</li>
	</ul>

	<div class="transactions__buttons" rv-if="meta.hasManualAccounts">
		<button class="button button--inline button--right button--small" rv-on-click="view.newTransaction">{ 'transactions.add' | string }</button>
		<button class="button button--inline button--secondary button--left button--small" rv-on-click="view.startReconcile">{ 'transactions.reconcile' | string }</button>
	</div>

	<div class="transactions__dropzone" rv-class-transactions__dropzone--visible="dropzone.visible">
		<div class="transactions__dropzone-wrap">
			<h4 class="transactions__dropzone-headline b-headline-l1">{ 'transactions.import.headline' | string }</h4>
			<p class="transactions__dropzone-message b-headline-l2">{ 'transactions.import.message' | string }</p>
		</div>
	</div>
</div>
`;
// Exports
export default code;