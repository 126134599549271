// Module
var code = `<ul class="header__list">
	<li class="header__item header__item--documents" rv-on-click="view.toggleDocuments">
		<button class="header__item-icon" rv-class-header__item-icon--active="documents.active" rv-title="'header.documents.title' | string">&#xe805;</button>
	</li>
	<li class="header__item header__item--connection header__item" rv-class-header__item--connection-disconnected="connection.disconnected" rv-class-header__item--connection-connecting="connection.connecting" rv-class-header__item--connection-connected="connection.connected" rv-class-header__item--connection-authenticating="connection.authenticating">
		<button class="header__connection" rv-on-click="view.toggleConnectionStatus">
			{ connection.string | string }
		</button>
	</li>
	<li class="header__item header__item--settings" rv-class-header__item--avatar="avatar.visible" rv-class-header__item--indicator="settings.indicator" rv-on-click="view.toggleSettings">
		<div class="header__item-avatar"><img rv-src="avatar.url" rv-if="avatar.url"></div>
		<button class="header__item-icon header__item-icon--settings" rv-class-header__item-icon--active="settings.active" rv-title="'header.documents.settings' | string">&#xe802;</button>
	</li>
	<li class="header__item header__item--title">{ title.content }</li>
</ul>
`;
// Exports
export default code;