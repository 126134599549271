// Module
var code = `<div class="transaction-add__content b-modal__content b-modal__content--fullscreen">
    <button class="transaction-add__back" rv-on-click="hide">←</button>
    <button class="transaction-add__location icon-location" rv-class-transaction-add__location--enabled="location.id" rv-class-transaction-add__location--active="location.coordinates" rv-class-transaction-add__location--error="location.error" rv-on-click="view.toggleLocation"></button>
    <div class="transaction-add__amount" rv-on-click="view.toggleModes" rv-class-transaction-add__amount--transfer="amount.mode | is 'transfer'">
        <span class="transaction-add__amount-prelabel" rv-if="amount.mode | is 'outflow'">{ 'transactionAdd.outflow.pre' | string }</span>
        <span class="transaction-add__amount-prelabel" rv-if="amount.mode | is 'income'">{ 'transactionAdd.income.pre' | string }</span>
        <span class="transaction-add__amount-prelabel" rv-if="amount.mode | is 'transfer'">{ 'transactionAdd.transfer.pre' | string }</span>
        <span class="transaction-add__amount-calculation" rv-if="amount.calculation" rv-html="amount.calculation"></span>
        <span class="transaction-add__amount-value" rv-class-transaction-add__amount-value--empty="amount.value | is 0" rv-class-transaction-add__amount-value--green="amount.mode | is 'income'" rv-class-transaction-add__amount-value--red="amount.mode | is 'outflow'">{ amount.value | currency }</span>
        <span class="transaction-add__amount-postlabel" rv-if="amount.mode | is 'outflow'">{ 'transactionAdd.outflow.post' | string }</span>
        <span class="transaction-add__amount-postlabel" rv-if="amount.mode | is 'income'">{ 'transactionAdd.income.post' | string }</span>
        <span class="transaction-add__amount-postlabel" rv-if="amount.mode | is 'transfer'">{ 'transactionAdd.transfer.post' | string }</span>
    </div>
    <div class="transaction-add__options">
        <button class="transaction-add__option transaction-add__account" rv-on-click="view.updateAccount">
            <span class="transaction-add__account-type" rv-if="amount.mode | is 'transfer'">{ 'transactionAdd.transfer.from' | string }</span>
            <span class="transaction-add__account-name">{ account.model:name }</span>
            <span class="transaction-add__account-balance" rv-if="account.model">
                { account.model:balance | currency } → { account.after | currency }
            </span>
        </button>
        <button class="transaction-add__option transaction-add__account" rv-if="amount.mode | is 'transfer'" rv-on-click="view.updateTransferAccount">
            <span class="transaction-add__account-type">{ 'transactionAdd.transfer.to' | string }</span>
            <span class="transaction-add__account-name">{ transferAccount.model:name }</span>
            <span class="transaction-add__account-balance" rv-if="transferAccount.model">
                { transferAccount.model:balance | currency } → { transferAccount.after | currency }
            </span>
        </button>
    </div>
    <div class="transaction-add__keyboard">
        <button rv-on-click="view.onKeyboardPress" class="transaction-add__keyboard-button">1</button>
        <button rv-on-click="view.onKeyboardPress" class="transaction-add__keyboard-button">2</button>
        <button rv-on-click="view.onKeyboardPress" class="transaction-add__keyboard-button">3</button>
        <button rv-on-click="view.onKeyboardPress" class="transaction-add__keyboard-button transaction-add__keyboard-button--calculator">÷</button>
        <button rv-on-click="view.onKeyboardPress" class="transaction-add__keyboard-button">4</button>
        <button rv-on-click="view.onKeyboardPress" class="transaction-add__keyboard-button">5</button>
        <button rv-on-click="view.onKeyboardPress" class="transaction-add__keyboard-button">6</button>
        <button rv-on-click="view.onKeyboardPress" class="transaction-add__keyboard-button transaction-add__keyboard-button--calculator">×</button>
        <button rv-on-click="view.onKeyboardPress" class="transaction-add__keyboard-button">7</button>
        <button rv-on-click="view.onKeyboardPress" class="transaction-add__keyboard-button">8</button>
        <button rv-on-click="view.onKeyboardPress" class="transaction-add__keyboard-button">9</button>
        <button rv-on-click="view.onKeyboardPress" class="transaction-add__keyboard-button transaction-add__keyboard-button--calculator">−</button>
        <button rv-on-click="view.onKeyboardPress" class="transaction-add__keyboard-button">⇆</button>
        <button rv-on-click="view.onKeyboardPress" class="transaction-add__keyboard-button">0</button>
        <button rv-on-click="view.onKeyboardPress" class="transaction-add__keyboard-button">←</button>
        <button rv-on-click="view.onKeyboardPress" class="transaction-add__keyboard-button transaction-add__keyboard-button--calculator">+</button>
    </div>
    <div class="transaction-add__buttons" rv-class-transaction-add__buttons--nosave="amount.value | is 0">
        <button class="b-form-button b-form-button--cancel transaction-add__button--cancel" rv-on-click="hide">{ 'transactionAdd.cancel' | string }</button>
        <button class="b-form-button b-form-button--save transaction-add__button--save" rv-on-click="save">{ 'transactionAdd.save' | string }</button>
    </div>
</div>
`;
// Exports
export default code;