// Module
var code = `<form class="document-general-settings__form b-form b-loader b-loader--light" rv-on-submit="view.submit" rv-class-loading="document:isSyncing">
	<h1 class="document-general-settings__headline b-headline-l1">{ 'documentSettingsGeneral.headline' | string }</h1>
	<h2 class="document-general-settings__document b-headline-l2">{ document:name }</h2>

	<label class="b-form-label" for="document-general-settings__input--name">
		{ 'documentSettingsGeneral.labels.name' | string }
	</label>
	<input type="text" name="name" id="document-general-settings__input--name" class="b-form-input" rv-value="document:name" rv-on-change="view.save">

	<label class="document-settings_label b-form-label" for="document-general-settings__input--name">
		{ 'documentSettingsGeneral.labels.currency' | string }
	</label>
	<span class="b-form-select-wrap">
		<select name="region" id="document-settings_input--region" class="document-settings_input document-settings_input--region b-form-select" rv-value="document:settings.currency | fallback 'euro'" rv-on-change="view.save">
			<option value="euro">{ 'documentSettingsGeneral.currency.euro' | string }</option>
			<option value="dollar">{ 'documentSettingsGeneral.currency.dollar' | string }</option>
		</select>
	</span>

	<span class="b-form-label">{ 'documentSettingsGeneral.labels.budgets' | string }</span>
	<ul class="document-general-settings__categories" rv-class-document-general-settings__categories--with-hidden="meta.showHidden">
		<li class="document-general-settings__category" rv-each-category="categories">
			<button class="document-general-settings__category-header" type="button" rv-on-click="category.settings">
				<span class="document-general-settings__category-name">{ category.name }</span>
				<span class="document-general-settings__category-settings">&#xe802;</span>
			</button>
			<ul class="document-general-settings__budgets">
				<li class="document-general-settings__budget" rv-each-budget="category.budgets" rv-class-document-general-settings__budget--hidden="budget.hidden">
					<button class="document-general-settings__budget-button" rv-on-click="budget.settings" type="button">
						<span class="document-general-settings__budget-name">{ budget.name }</span>
						<span class="document-general-settings__budget-settings">&#xe802;</span>
					</button>
				</li>
			</ul>
		</li>
	</ul>

	<button class="document-general-settings__toggle-hidden-button" type="button" rv-on-click="view.toggleHidden">
		<span rv-unless="meta.showHidden">▾ { 'documentSettingsGeneral.budgets.showHidden' | string }</span>
		<span rv-if="meta.showHidden">▴ { 'documentSettingsGeneral.budgets.hideHidden' | string }</span>
	</button>

	<div class="document-general-settings__add-buttons">
		<button class="document-general-settings__add" type="button" rv-on-click="view.addCategory">
			<span class="document-general-settings__add-plus">+</span>
			<span class="document-general-settings__add-text">{ 'documentSettingsGeneral.budgets.addCategory' | string }</span>
		</button>
		<button class="document-general-settings__add" type="button" rv-on-click="view.addBudget">
			<span class="document-general-settings__add-plus">+</span>
			<span class="document-general-settings__add-text">{ 'documentSettingsGeneral.budgets.addBudget' | string }</span>
		</button>
	</div>

	<div class="b-danger" rv-class-loading="meta.deleting">
		<h3 class="b-danger__headline">{ 'documentSettingsGeneral.delete.title' | string }</h3>
		<p class="b-danger__message">{ 'documentSettingsGeneral.delete.message' | string }</p>

		<button class="b-danger__button" type="button" rv-on-click="view.delete">{ 'documentSettingsGeneral.delete.button' | string }</button>
	</div>
</form>`;
// Exports
export default code;