// Module
var code = `<form class="document-settings-plugin-details__form b-form b-loader b-loader--light" rv-on-submit="view.submit" rv-class-loading="pluginInstance:isSyncing">
	<h1 class="document-settings-plugin-details__headline b-headline-l1">{ 'documentSettingsPluginDetails.headline' | string }</h1>
	<h2 class="document-settings-plugin-details__document b-headline-l2">
		{ meta.plugin:name }
		<span class="document-settings-plugin-details__status" rv-data-status="status.class">{ status.text }</span>
	</h2>

	<div class="document-settings-plugin-details__config" rv-each-config="pluginInstance:config">
		<label class="b-form-label" rv-for="config.id | append 'document-settings-plugin-details__input--'">
			{ config.label | string }
		</label>

		<input rv-type="config.type" class="b-form-input" rv-name="config.id" rv-id="config.id | append 'document-settings-plugin-details__input--'" rv-class-b-form-input--error="config.lastError" rv-value="config.value | fallback config.defaultValue" rv-placeholder="config.placeholder">
	</div>

	<div class="document-settings-plugin-details__error" rv-if="pluginInstance:errors.getConfig">
		<span class="document-settings-plugin-details__error-method">{ 'documentSettingsPluginDetails.error.getConfig' }</span>
		<pre class="document-settings-plugin-details__error-message">{ pluginInstance:errors.getConfig }</pre>
	</div>
	<div class="document-settings-plugin-details__error" rv-if="pluginInstance:errors.getSupported">
		<span class="document-settings-plugin-details__error-method">{ 'documentSettingsPluginDetails.error.getSupported' }</span>
		<pre class="document-settings-plugin-details__error-message">{ pluginInstance:errors.getSupported }</pre>
	</div>
	<div class="document-settings-plugin-details__error" rv-if="pluginInstance:errors.validateConfig">
		<span class="document-settings-plugin-details__error-method">{ 'documentSettingsPluginDetails.error.validateConfig' }</span>
		<pre class="document-settings-plugin-details__error-message">{ pluginInstance:errors.validateConfig }</pre>
	</div>
	<div class="document-settings-plugin-details__error" rv-if="pluginInstance:errors.getTransactions">
		<span class="document-settings-plugin-details__error-method">{ 'documentSettingsPluginDetails.error.getTransactions' }</span>
		<pre class="document-settings-plugin-details__error-message">{ pluginInstance:errors.getTransactions }</pre>
	</div>
	<div class="document-settings-plugin-details__error" rv-if="pluginInstance:errors.getMetadata">
		<span class="document-settings-plugin-details__error-method">{ 'documentSettingsPluginDetails.error.getMetadata' }</span>
		<pre class="document-settings-plugin-details__error-message">{ pluginInstance:errors.getMetadata }</pre>
	</div>
	<div class="document-settings-plugin-details__error" rv-if="pluginInstance:errors.getGoals">
		<span class="document-settings-plugin-details__error-method">{ 'documentSettingsPluginDetails.error.getGoals' }</span>
		<pre class="document-settings-plugin-details__error-message">{ pluginInstance:errors.getGoals }</pre>
	</div>

	<div class="document-settings-plugin-details__info-wrap" rv-if="meta.plugin">
		<div class="document-settings-plugin-details__info">
			<h4 class="document-settings-plugin-details__info-header">
				{ 'documentSettingsPluginAddDetails.info.header' | string }
			</h4>
			<dl class="b-dl">
				<dt rv-if="meta.plugin:author.name">{ 'documentSettingsPluginAddDetails.info.author' | string }</dt>
				<dd rv-if="meta.plugin:author.url"><a rv-href="meta.plugin:author.url" target="_blank">{ meta.plugin:author.name }</a></dd>
				<dd rv-unless="details.author.url">{ meta.plugin:author.name }</dd>
				<dt>{ 'documentSettingsPluginAddDetails.info.size' | string }</dt>
				<dd>{ meta.plugin:size.latest | filesize }</dd>
				<dt>{ 'documentSettingsPluginAddDetails.info.version' | string }</dt>
				<dd>{ meta.plugin:channels.latest }</dd>
				<dt rv-if="meta.plugin:license">{ 'documentSettingsPluginAddDetails.info.license' | string }</dt>
				<dd rv-if="meta.plugin:license">{ meta.plugin:license }</dd>
				<dt rv-if="meta.plugin:website">{ 'documentSettingsPluginAddDetails.info.website' | string }</dt>
				<dd rv-if="meta.plugin:website"><a rv-href="meta.plugin:website" class="icon-globe" target="_blank">&nbsp;</a></dd>
				<dt rv-if="meta.plugin:issues.url">{ 'documentSettingsPluginAddDetails.info.issues' | string }</dt>
				<dd rv-if="meta.plugin:issues.url"><a rv-href="meta.plugin:issues.url" class="icon-globe" target="_blank">&nbsp;</a></dd>
				<dt>{ 'documentSettingsPluginAddDetails.info.npm' | string }</dt>
				<dd><a rv-href="'https://www.npmjs.com/package/' | append meta.plugin:id" target="_blank">{ meta.plugin:id }</a></dd>
			</dl>
		</div>
		<div class="document-settings-plugin-details__info">
			<h4 class="document-settings-plugin-details__info-header">
				{ 'documentSettingsPluginAddDetails.compatibility.header' | string }
			</h4>
			<div class="b-dl">
				<div rv-each-c="meta.compatibility">
					<div class="b-dt">{ 'documentSettingsPluginAddDetails.compatibility.' | append c.type | string }</div>
					<div class="b-dd document-settings-plugin-details__info-bullet" rv-class-document-settings-plugin-details__info-bullet--ok="c.ok" rv-class-document-settings-plugin-details__info-bullet--warn="c.warn" rv-class-document-settings-plugin-details__info-bullet--err="c.error">
						{ c.requirement }
					</div>
				</div>
			</div>
		</div>
		<div class="document-settings-plugin-details__info">
			<h4 class="document-settings-plugin-details__info-header">
				{ 'documentSettingsPluginAddDetails.responsibilities.header' | string }
			</h4>
			<div class="b-dl">
				<div rv-each-provider="meta.plugin:responsibilities">
					<div class="b-dt">{ provider.name }</div>
					<div class="b-dd">
						<ul class="document-settings-plugin-details__features">
							<li class="document-settings-plugin-details__feature" rv-if="provider.account">
								{ 'documentSettingsPluginAddDetails.responsibilities.account' | string }
							</li>
							<li class="document-settings-plugin-details__feature" rv-if="provider.goal">
								{ 'documentSettingsPluginAddDetails.responsibilities.goal' | string }
							</li>
							<li class="document-settings-plugin-details__feature" rv-if="provider.metadata">
								{ 'documentSettingsPluginAddDetails.responsibilities.metadata' | string }
							</li>
							<li class="document-settings-plugin-details__feature" rv-if="provider.webhook">
								{ 'documentSettingsPluginAddDetails.responsibilities.webhook' | string }
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="b-form-buttons">
		<button type="submit" class="b-form-button b-form-button--save">{ 'documentSettingsAccountDetails.done' | string }</button>
	</div>

	<div class="b-danger" rv-class-loading="meta.uninstalling">
		<h3 class="b-danger__headline">{ 'documentSettingsPluginDetails.uninstall.title' | string }</h3>
		<p class="b-danger__message">{ 'documentSettingsPluginDetails.uninstall.message' | string }</p>

		<button class="b-danger__button" type="button" rv-on-click="view.uninstall">{ 'documentSettingsPluginDetails.uninstall.button' | string }</button>
	</div>
</form>`;
// Exports
export default code;