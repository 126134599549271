// Module
var code = `<form class="category-editor__content b-modal__content b-form" rv-on-submit="view.submit">
	<h2 class="b-headline-l1">{ 'categoryEditor.title' | string }</h2>
	<h3 class="b-headline-l2">{ model:name }</h3>

	<label class="b-form-label" for="category-editor__input--name">{ 'categoryEditor.labels.name' | string }</label>
	<input type="text" name="name" id="category-editor__input--name" rv-value="model:name" autocomplete="disabled" class="b-form-input">

	<div class="b-danger" rv-if="delete.showDeleteAndMove" rv-class-loading="delete.deleting">
		<h3 class="b-danger__headline">{ 'categoryEditor.delete.title' | string }</h3>
		<p class="b-danger__message">{ 'categoryEditor.delete.move.message' | string }</p>

		<label class="b-form-label" for="category-editor__input--name">{ 'categoryEditor.labels.category' | string }</label>
		<div class="b-form-select-wrap">
			<select name="category" id="category-editor__input--category" class="b-form-select" rv-value="delete.categoryId">
				<option rv-value="category:id" rv-each-category="delete.categories">{ category:name }</option>
			</select>
		</div>

		<button class="b-danger__button" type="button" rv-on-click="view.moveAndDelete">{ 'categoryEditor.delete.move.button' | string }</button>
	</div>
	<div class="b-danger" rv-if="delete.showDelete" rv-class-loading="delete.deleting">
		<h3 class="b-danger__headline">{ 'categoryEditor.delete.title' | string }</h3>
		<p class="b-danger__message">{ 'categoryEditor.delete.message' | string }</p>

		<button class="b-danger__button" type="button" rv-on-click="view.delete">{ 'categoryEditor.delete.button' | string }</button>
	</div>

	<div class="b-form-buttons">
		<button class="b-form-button b-form-button--save">{ 'categoryEditor.done' | string }</button>
	</div>
</form>
`;
// Exports
export default code;