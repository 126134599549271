// Module
var code = `<div class="login__wrap">
	<div class="login__icons" rv-html="meta.icons"></div>
	<div class="login__logo"></div>

	<form class="login__form" rv-on-submit="view.submit">
		<div class="login__form-box" rv-class-login__form-box--error="meta.error" rv-unless="terms.visible">
			<input class="login__form-input login__form-input--email" type="email" autocomplete="username" enterkeyhint="go" rv-placeholder="'login.email.placeholder' | string" rv-value="form.username" required>
			<input class="login__form-input login__form-input--password" type="password" autocomplete="current-password" enterkeyhint="go" rv-placeholder="'login.password.placeholder' | string" rv-value="form.password" required>
		</div>

		<div class="login__terms" rv-if="terms.visible">
			<input type="checkbox" class="login__terms-checkbox" rv-checked="terms.checked" rv-on-change="view.updateDisabledState">
			<span class="login__terms-message" rv-html="terms.content"></span>
		</div>

		<button type="submit" class="button login__submit" rv-disabled="meta.disabled" rv-unless="terms.visible">{ 'login.submit.text' | string }</button>
		<button type="submit" class="button login__submit" rv-disabled="meta.disabled" rv-if="terms.visible">{ 'login.continue.text' | string }</button>

		<div class="login__minilinks" rv-unless="terms.visible">
			<a rv-href="'login.forgotPassword.url' | string">{ 'login.forgotPassword.text' | string }</a>
		</div>
	</form>
</div>
<div class="login__footer">
	&copy; { meta.year } <a rv-href="'login.website.url' | string">{ 'login.website.text' | string }</a> |
	<a rv-href="'login.help.url' | string">{ 'login.help.text' | string}</a>
</div>
`;
// Exports
export default code;