// Module
var code = `<form class="budget-editor__content b-modal__content b-form" rv-on-submit="view.submit">
	<h2 class="b-headline-l1">{ 'budgetEditor.title' | string }</h2>
	<h3 class="b-headline-l2">{ model:name }</h3>

	<label class="b-form-label" for="budget-editor__input--name">{ 'budgetEditor.labels.name' | string }</label>
	<input type="text" name="name" id="budget-editor__input--name" rv-value="model:name" autocomplete="disabled" enterkeyhint="done" class="b-form-input">

	<label class="b-form-label" for="budget-editor__input--category">{ 'budgetEditor.labels.category' | string }</label>
	<span class="b-form-select-wrap">
			<select name="category" id="budget-editor__input--category" class="b-form-select" rv-value="model:categoryId">
				<option rv-value="category:id" rv-each-category="categories:models">{ category:name }</option>
			</select>
		</span>

	<label class="b-form-label" for="budget-editor__input--goal">{ 'budgetEditor.labels.goal' | string }</label>
	<div class="budget-editor__goal">
		<input type="checkbox" class="budget-editor__goal-checkbox" rv-checked="goal.checkbox.checked" rv-on-click="view.clickGoalCheckbox">

		<input type="text" name="goal" id="budget-editor__input--goal" class="budget-editor__goal-input" inputmode="decimal" rv-class-budget-editor__goal-input--empty="goal.input.disabled" rv-currency-value="model:goal">
	</div>
	<div class="b-form-buttons">
		<button class="b-form-button b-form-button--hide" type="button" rv-on-click="view.toggleHidden" rv-unless="model:hidden">{ 'budgetEditor.hide' | string }
		</button>
		<button class="b-form-button b-form-button--remove budget-editor__remove" type="button" rv-on-click="view.delete" rv-if="model:deletable">{ 'budgetEditor.remove' | string }
		</button>
		<button class="b-form-button b-form-button--unhide budget-editor__unhide" type="button" rv-on-click="view.toggleHidden" rv-if="model:hidden">{ 'budgetEditor.show' | string }
		</button>
		<button class="b-form-button b-form-button--save">{ 'budgetEditor.done' | string }</button>
	</div>
</form>
`;
// Exports
export default code;